import PropTypes from 'prop-types';
import { useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import zalo from '../assets/images/zalo.png';
import messenger from '../assets/images/messenger.png';
// ==============================|| NAVIGATION SCROLL TO TOP ||============================== //

const NavigationScroll = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return (
        <Fragment>
            <a href="https://zalo.me/0363991734" target="_blank">
                <img src={zalo} alt="zalo" style={{ width: '4rem', position: 'fixed', zIndex: '9999999', left: '1rem', bottom: '1rem' }} />
            </a>
            <a href="http://m.me/103106099166621" target="_blank">
                <img
                    src={messenger}
                    alt="messenger"
                    style={{ width: '4rem', position: 'fixed', zIndex: '9999999', right: '1rem', bottom: '1rem' }}
                />
            </a>
            {children || null}
        </Fragment>
    );
};

NavigationScroll.propTypes = {
    children: PropTypes.node
};

export default NavigationScroll;
