import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Login from './views/pages/authentication/authentication3/Login3';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthProvider } from 'routes/useAuth';
import { getUserInfo } from 'auth/api/user';
import swal from 'sweetalert';
import { useEffect } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
    useEffect(() => {
        getUserInfo().then((response) => {
            if (response.success && response.data.download && response.data.download.length > 0) {
                response.data.download.forEach((item) => {
                    const expire_at = new Date(item.detail.expire_at).getTime();
                    if (item.name === 'Gói tháng' && expire_at < Date.now()) {
                        swal('Vui lòng gia hạn tài khoản hoặc nạp thêm điểm để tải!', '', 'error');
                        return;
                    }
                });
            }
            localStorage.setItem('userInfo', JSON.stringify(response.data));
        });
    }, []);
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
