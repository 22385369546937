import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const DownloadFile = Loadable(lazy(() => import('views/utilities/DownloadFile')));
const PermanentDownloadFile = Loadable(lazy(() => import('views/utilities/PermanentDownloadFile')));
const History = Loadable(lazy(() => import('views/utilities/History')));
const Information = Loadable(lazy(() => import('views/utilities/Information')));
const ChangePass = Loadable(lazy(() => import('views/utilities/ChangePass')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// check login
import { ProtectedRoute } from './ProtectedRoute';
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: (
                <ProtectedRoute>
                    <DownloadFile />
                </ProtectedRoute>
            )
        },
        {
            path: 'permanent-downloadFile',
            element: (
                <ProtectedRoute>
                    <PermanentDownloadFile />
                </ProtectedRoute>
            )
        },
        {
            path: 'history',
            element: (
                <ProtectedRoute>
                    <History />
                </ProtectedRoute>
            )
        },
        {
            path: 'information',
            element: (
                <ProtectedRoute>
                    <Information />
                </ProtectedRoute>
            )
        },
        {
            path: 'changePass',
            element: (
                <ProtectedRoute>
                    <ChangePass />
                </ProtectedRoute>
            )
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
