import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { login, logout } from '../auth/api/auth';
import { getUserInfo } from 'auth/api/user';
import swal from 'sweetalert';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null);
    const navigate = useNavigate();
    // call this function when you want to authenticate the user
    const loginAuth = async (data) => {
        login(data)
            .then((res) => {
                if (res.success) {
                    setUser(res.accessToken);
                    localStorage.setItem('accessToken', res.accessToken);
                    getUserInfo().then((response) => {
                        if (response.success && response.data.download && response.data.download.length > 0) {
                            response.data.download.forEach((item) => {
                                const expire_at = new Date(item.detail.expire_at).getTime();
                                if (item.name === 'Gói tháng' && expire_at < Date.now()) {
                                    swal('Vui lòng gia hạn tài khoản hoặc nạp thêm điểm để tải!', '', 'error');
                                    return;
                                }
                            });
                        }
                        localStorage.setItem('userInfo', JSON.stringify(response.data));
                    });
                    navigate('/');
                } else if (res.error) {
                    swal(res.message, '', 'error');
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    swal(err.response?.data.message, '', 'error');
                } else if (err.response?.status === 404) {
                    swal(err.response?.data.message, '', 'error');
                }
            });
    };

    // call this function to sign out logged in user
    const logoutAuth = () => {
        logout()
            .then((res) => {
                if (res.success) {
                    setUser(null);
                    localStorage.clear();
                    navigate('/auth-login');
                }
            })
            .catch((err) => {
                if (err.response.status == 403) {
                    setUser(null);
                    localStorage.clear();
                    navigate('/auth-login');
                }
            });
    };
    const value = useMemo(
        () => ({
            user,
            loginAuth,
            logoutAuth
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
