// assets
import { IconWindmill, IconDownload, IconHistory, IconInfoCircle, IconKey } from '@tabler/icons';

// constant
const icons = {
    IconWindmill,
    IconDownload,
    IconHistory,
    IconInfoCircle,
    IconKey
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    type: 'group',
    children: [
        {
            id: 'util-downloadFile',
            title: 'Tải File',
            type: 'item',
            url: '/',
            icon: icons.IconDownload,
            breadcrumbs: false
        },
        {
            id: 'util-permanent-downloadFile',
            title: 'Tải Pikbest/Pngtree (vĩnh viễn)',
            type: 'item',
            url: '/permanent-downloadFile',
            icon: icons.IconDownload,
            breadcrumbs: false
        },
        {
            id: 'util-history',
            title: 'Lịch sử',
            type: 'item',
            url: '/history',
            icon: icons.IconHistory,
            breadcrumbs: false
        },
        {
            id: 'util-information',
            title: 'Thông tin',
            type: 'item',
            url: '/information',
            icon: icons.IconInfoCircle,
            breadcrumbs: false
        },
        {
            id: 'util-changePass',
            title: 'Đổi mật khẩu',
            type: 'item',
            url: '/changePass',
            icon: icons.IconKey,
            breadcrumbs: false
        }
    ]
};

export default utilities;
