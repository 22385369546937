import { API } from '../libs/client';

export const login = async (data) => {
    const res = await API.post(`/users/login`, data);
    return res.data;
};

export const logout = async () => {
    const res = await API.get(`/users/logout`);
    return res.data;
};

//register
export const register = async (data) => {
    const res = await API.post(`/users/signup`, data);
    return res.data;
};

// forgot password
export const forgotPass = async (data) => {
    const res = await API.patch(`/users/forgot`, data);
    return res.data;
};

// change password
export const changePass = async (data) => {
    const res = await API.patch(`/users/change`, data);
    return res.data;
};

//active account
export const activeAcc = async (data) => {
    const res = await API.patch(`/users/activate`, data);
    return res.data;
};

//reset password
export const resetPass = async (data) => {
    const res = await API.patch(`/users/reset`, data);
    return res.data;
};
