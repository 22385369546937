import axios from 'axios';

function getAuthToken() {
    return window.localStorage.getItem('accessToken') ?? '';
}

const API = axios.create({
    baseURL: process.env.REACT_APP_URL_API + 'api/v1'
});

API.interceptors.request.use((config) => {
    config.headers = {
        ...(config.headers ?? {}),
        Authorization: `Bearer ${getAuthToken()}`
    };
    return { ...config };
});

export { API };
