import { API } from '../libs/client';

//get user info
export const getUserInfo = async (data) => {
    const res = await API.get(`/users/detail`, data);
    return res.data;
};

//edit information
export const editUser = async (data) => {
    const res = await API.patch(`/users/edit`, data);
    return res.data;
};

// history
export const getHistory = async (params) => {
    const res = await API.get(`/history/list?${params}`);
    return res.data;
};
